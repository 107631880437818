import Link from 'next/link';
import Image from 'next/image';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import styles from './main.footer.scss';

import { baseURLV1 } from '../../../../api/axiosInstance';

import websiteConfig from '../../../../configs/websiteConfig';
import FacebookIcon from '../../../../assets/images/icons/fb.svg';
import InstagramIcon from '../../../../assets/images/icons/instagram.svg';
import LinkedInIcon from '../../../../assets/images/icons/linkedin.svg';
import TelegramIcon from '../../../../assets/images/icons/telegram.svg';
import PinterestIcon from '../../../../assets/images/icons/pinterest.svg';
import TwitterIcon from '../../../../assets/images/icons/twitter.svg';
import YoutubeIcon from '../../../../assets/images/icons/youtube.svg';
import LogoImg from '../../../../assets/images/logos/logo.png';
import FlagImg from '../../../../assets/images/flags/footer_flag.jpeg';
import BetFoundersImg from '../../../../assets/images/logos/betfounders.svg';

import { getDynamicText_req } from '../../../../api/meta.api';

export default function MainFooter() {
  const intl = useIntl();
  const router = useRouter();

  const { operators } = useSelector((state) => state.global);
  const [pageDynamicText, setPageDynamicText] = useState();

  const { asPath, locale } = router;

  const getDynamicTextForThePage = useCallback(async () => {
    try {
      const dynamicTextData = await getDynamicText_req(
        asPath.replace('/', ''),
        locale
      );

      if (dynamicTextData) {
        setPageDynamicText(dynamicTextData);
      }
    } catch (e) {
      console.error('MainFooter -> getDynamicTextForThePage Error:', e);
    }
  }, [asPath, locale]);

  const onChatClick = useCallback(() => {
    if (window?.Tawk_API) {
      window.Tawk_API.toggle();
    }
  }, []);

  useEffect(() => {
    getDynamicTextForThePage();
  }, [asPath, locale]);

  return (
    <>
      <footer>
        {pageDynamicText && (
          <div className="footer_info_wrapper">
            {pageDynamicText.h1Title && (
              <div className="footer_info_title">
                <h1>{pageDynamicText.h1Title}</h1>
              </div>
            )}
            {pageDynamicText.h1 && (
              <div className="footer_info_content">
                <p>{pageDynamicText.h1}</p>
              </div>
            )}
            {pageDynamicText.h2Title && (
              <div className="footer_info_title">
                <h2>{pageDynamicText.h2Title}</h2>
              </div>
            )}
            {pageDynamicText.h2 && (
              <div className="footer_info_content">
                <p>{pageDynamicText.h2}</p>
              </div>
            )}
            {pageDynamicText.h3Title && (
              <div className="footer_info_title">
                <h3>{pageDynamicText.h3Title}</h3>
              </div>
            )}
            {pageDynamicText.h3 && (
              <div className="footer_info_content">
                <p>{pageDynamicText.h3}</p>
              </div>
            )}
          </div>
        )}
        <div className="links">
          <div>
            <h3>{intl.messages.useful_links}</h3>
            <ul>
              <li>
                <Link
                  href="/promotions/deposit-bonus"
                  as="/promotions/deposit-bonus"
                >
                  <a>{intl.messages.deposit_bonus}</a>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3>{intl.messages.contact_us}</h3>
            <ul>
              {websiteConfig.messenger && (
                <li>
                  <a
                    href={websiteConfig.messenger}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Messenger
                  </a>
                </li>
              )}

              <li>
                <span onClick={onChatClick}>{intl.messages.live_chat}</span>
              </li>
              {websiteConfig.phone && (
                <li>
                  <a href={`tel:${websiteConfig.phone}`}>
                    {intl.messages.customer_center}
                  </a>
                </li>
              )}

              {websiteConfig.phone && (
                <li>
                  <a href={`tel:${websiteConfig.phone}`}>
                    {websiteConfig.phone}
                  </a>
                </li>
              )}

              {websiteConfig.email && (
                <li>
                  <a href={`mailto:${websiteConfig.email}`}>
                    {websiteConfig.email}
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div>
            <h3>{intl.messages.support_and_trust}</h3>
            <ul>
              <li>
                <a href="/terms" target="_blank" rel="noreferrer">
                  {intl.messages.terms_and_conditions}
                </a>
              </li>
            </ul>
          </div>
          <div className="payments_and_social">
            {operators?.length ? (
              <Link href="/deposit" as="/deposit">
                <a>
                  <div className="payments_wrapper">
                    <h3>{intl.messages.accepting_payments_via}</h3>
                    <ul>
                      {operators.map((data) => (
                        <li key={data.Name}>
                          <img
                            src={`${baseURLV1}/media/${data.MediaID}`}
                            alt={data.Name}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </a>
              </Link>
            ) : (
              <></>
            )}
            {websiteConfig.socialEnabled && (
              <div className="social_wrapper">
                <h3>{intl.messages.find_us_on}</h3>
                <ul>
                  {websiteConfig.facebook && (
                    <li>
                      <a
                        href={websiteConfig.facebook}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={FacebookIcon}
                          alt="Facebook"
                          width={12}
                          height={12}
                        />
                      </a>
                    </li>
                  )}
                  {websiteConfig.instagram && (
                    <li>
                      <a
                        href={websiteConfig.instagram}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={InstagramIcon}
                          alt="Instagram"
                          width={12}
                          height={12}
                        />
                      </a>
                    </li>
                  )}
                  {websiteConfig.linkedin && (
                    <li>
                      <a
                        href={websiteConfig.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={LinkedInIcon}
                          alt="Linkedin"
                          width={12}
                          height={12}
                        />
                      </a>
                    </li>
                  )}
                  {websiteConfig.telegram && (
                    <li>
                      <a
                        href={websiteConfig.telegram}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={TelegramIcon}
                          alt="Telegram"
                          width={12}
                          height={12}
                        />
                      </a>
                    </li>
                  )}
                  {websiteConfig.pinterest && (
                    <li>
                      <a
                        href={websiteConfig.pinterest}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image
                          src={PinterestIcon}
                          alt="Pinterest"
                          width={12}
                          height={12}
                        />
                      </a>
                    </li>
                  )}
                  {websiteConfig.twitter && (
                    <li>
                      <a
                        href={websiteConfig.twitter}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image src={TwitterIcon} alt="Twitter" />
                      </a>
                    </li>
                  )}
                  {websiteConfig.youtube && (
                    <li>
                      <a
                        href={websiteConfig.youtube}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Image src={YoutubeIcon} alt="Youtube" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div className="bottom_content">
          <div className="plus_18">18+</div>
          <Image
            src={LogoImg}
            width={89}
            height={37}
            alt={`${websiteConfig.name} Logo`}
            className="logo_dark_img"
          />
          <Image
            src={FlagImg}
            width={26}
            height={14}
            alt={websiteConfig.country}
            className="flag_img"
          />
        </div>
        {websiteConfig.betFoundersEnabled && (
          <div className="bet_founders">
            <a href="https://betfounders.com" target="_blank" rel="noreferrer">
              <Image
                src={BetFoundersImg}
                width={200}
                alt="BetFounders"
                className="flag_img"
              />
            </a>
          </div>
        )}
      </footer>
      <style jsx>{styles}</style>
    </>
  );
}
